<template>
  <Page #default="{}" class="text-justify">
    <h1 class="mb-4">Conditions générales d'utilisation</h1>

    <h4>ARTICLE 1 : Objet</h4>

    <p>
      Les présentes « conditions générales d'utilisation » ont pour objet
      l'encadrement juridique de l’utilisation du site Vocca et de ses services.
      Ce contrat est conclu entre : Le gérant du site internet, ci-après désigné
      « l’Éditeur », Toute personne physique ou morale souhaitant accéder au
      site et à ses services, ci-après appelé « l’Utilisateur ». Les conditions
      générales d'utilisation doivent être acceptées par tout Utilisateur, et
      son accès au site vaut acceptation de ces conditions.
    </p>

    <h4>ARTICLE 2 : Mentions légales</h4>

    <p>Le site Vocca est maintenu et édité par AASSILA Younes.</p>

    <h4>ARTICLE 3 : Accès aux services</h4>

    <p>L’Utilisateur du site Vocca a accès aux services suivants :</p>

    <ul>
      <li>Création de compte ;</li>
      <li>Création de classes ;</li>
      <li>Création de listes de vocabulaire.</li>
    </ul>

    <p>
      Tout Utilisateur ayant accès à internet peut accéder gratuitement et
      depuis n’importe où au site. Les frais supportés par l’Utilisateur pour y
      accéder (connexion internet, matériel informatique, etc.) ne sont pas à la
      charge de l’Éditeur. Les services suivants ne sont accessibles pour
      l’Utilisateur que s’il est membre du site (c’est-à-dire qu’il est
      identifié à l’aide de ses identifiants de connexion) :
    </p>

    <ul>
      <li>Création de classes ;</li>
      <li>Création de listes de vocabulaire.</li>
    </ul>

    <p>
      Le site et ses différents services peuvent être interrompus ou suspendus
      par l’Éditeur, notamment à l’occasion d’une maintenance, sans obligation
      de préavis ou de justification.
    </p>

    <h4>ARTICLE 4 : Responsabilité de l’Utilisateur</h4>

    <p>
      L'Utilisateur est responsable des risques liés à l’utilisation de son
      identifiant de connexion et de son mot de passe. Le mot de passe de
      l’Utilisateur doit rester secret. En cas de divulgation de mot de passe,
      l’Éditeur décline toute responsabilité. L’Utilisateur assume l’entière
      responsabilité de l’utilisation qu’il fait des informations et contenus
      présents sur le site Vocca. Tout usage du service par l'Utilisateur ayant
      directement ou indirectement pour conséquence des dommages doit faire
      l'objet d'une indemnisation au profit du site. Le site permet aux membres
      de publier sur le site :
    </p>
    <ul>
      <li>
        Des listes pouvant contenir des mots de vocabulaire ou expressions.
      </li>
    </ul>
    <p>
      Le membre s’engage à tenir des propos respectueux des autres et de la loi
      et accepte que ces publications soient modérées ou refusées par l’Éditeur,
      sans obligation de justification. En publiant sur le site, l’Utilisateur
      cède à l’Éditeur le droit non exclusif et gratuit de représenter,
      reproduire, adapter, modifier, diffuser et distribuer sa publication,
      directement ou par un tiers autorisé. L’Éditeur s'engage toutefois à citer
      le membre en cas d’utilisation de sa publication
    </p>

    <h4>ARTICLE 5 : Responsabilité de l’Éditeur</h4>

    <p>
      Tout dysfonctionnement du serveur ou du réseau ne peut engager la
      responsabilité de l’Éditeur. De même, la responsabilité du site ne peut
      être engagée en cas de force majeure ou du fait imprévisible et
      insurmontable d'un tiers. Le site Vocca s'engage à mettre en œuvre tous
      les moyens nécessaires pour garantir la sécurité et la confidentialité des
      données. Toutefois, il n’apporte pas une garantie de sécurité totale.
      L’Éditeur se réserve la faculté d’une non-garantie de la fiabilité des
      sources, bien que les informations diffusées sur le site soient réputées
      fiables.
    </p>

    <h4>ARTICLE 6 : Propriété intellectuelle</h4>

    <p>
      Les contenus du site Vocca (logos, textes, éléments graphiques, vidéos,
      etc.) sont protégés par le droit d’auteur, en vertu du Code de la
      propriété intellectuelle. L’Utilisateur devra obtenir l’autorisation de
      l’Éditeur du site avant toute reproduction, copie ou publication de ces
      différents contenus. Ces derniers peuvent être utilisés par les
      utilisateurs à des fins privées ; tout usage commercial est interdit.
      L’Utilisateur est entièrement responsable de tout contenu qu’il met en
      ligne et il s’engage à ne pas porter atteinte à un tiers. L’Éditeur du
      site se réserve le droit de modérer ou de supprimer librement et à tout
      moment les contenus mis en ligne par les utilisateurs, et ce sans
      justification.
    </p>

    <h4>ARTICLE 7 : Données personnelles</h4>

    <p>
      L’Utilisateur doit obligatoirement fournir des informations personnelles
      pour procéder à son inscription sur le site. L’adresse électronique
      (e-mail) de l’Utilisateur pourra notamment être utilisée par le site Vocca
      pour la communication d’informations diverses et la gestion du compte.
      Vocca garantie le respect de la vie privée de l’Utilisateur, conformément
      à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers
      et aux libertés. En vertu des articles 39 et 40 de la loi en date du 6
      janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification,
      de suppression et d'opposition de ses données personnelles. L'Utilisateur
      exerce ce droit via :
    </p>

    <ul>
      <li>Son espace personnel sur le site ;</li>
      <li>Un formulaire de contact.</li>
    </ul>

    <h4>ARTICLE 8 : Liens hypertextes</h4>

    <p>
      Il est possible pour un tiers de créer un lien vers une page du site Vocca
      sans autorisation expresse de l’Éditeur.
    </p>

    <h4>ARTICLE 9 : Évolution des conditions générales d’utilisation</h4>

    <p>
      Le site Vocca se réserve le droit de modifier les clauses de ces
      conditions générales d’utilisation à tout moment et sans justification.
    </p>

    <h4>ARTICLE 10 : Durée du contrat</h4>

    <p>
      La durée du présent contrat est indéterminée. Le contrat produit ses
      effets à l'égard de l'Utilisateur à compter du début de l’utilisation du
      service.
    </p>

    <h4>ARTICLE 11 : Droit applicable et juridiction compétente</h4>

    <p>
      Le présent contrat dépend de la législation française. En cas de litige
      non résolu à l’amiable entre l’Utilisateur et l’Éditeur, les tribunaux de
      Strasbourg sont compétents pour régler le contentieux.
    </p>

    <hr />

    <p>Dernière modification le 15/08/2020.</p>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";

export default {
  name: "TermsOfService",
  components: {
    Page,
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 2.5rem;
}
</style>
